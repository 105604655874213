import React, { useEffect } from "react"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"

import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import CTA from "../components/cta"

import "./service.scss"
import VerticalCarousel from "../components/vertical-carousel/vertical-carousel"
import ExplorerView from "../components/explorer-view/explorer-view"

const icons = {
  measure: require("../images/measure/measure-cover.svg"),
  health: require("../images/measure/health-carousel.inline.svg"),
  strength: require("../images/measure/strength-carousel.inline.svg"),
  homeOffice: require("../images/measure/home-office-carousel.inline.svg"),
  roi: require("../images/measure/roi-carousel.inline.svg"),
  detail: require("../images/measure/detail-carousel.inline.svg"),
  comparison: require("../images/measure/comparison-carousel.inline.svg"),
  pulse: require("../images/measure/pulse-carousel.inline.svg"),
  explorers: require("../images/measure/explorers-circle.svg"),
}

const Service = () => {
  const carouselDataMeasure = [
    {
      title: "Health Age​",
      desc: "In peak condition for your age? Learn if your health matches your work ambition at personal, team and company levels​.",
      contentImg: <icons.health />,
      color: "purple",
      tab: "Age",
      rightCut: true,
    },
    {
      title: "​Psychometric strength​",
      desc: "Stress, anxiety and depression materially impact work performance. Get an accurate snapshot of the mental health of your people to help you finetune interventions.​",
      contentImg: <icons.strength />,
      color: "teal",
      tab: "Mind",
    },
    {
      title: "​Home, office, or both?​",
      desc: "It’s about people and places. Find the natural performance habitat for your people. Empower productive practice with SHAPE tools to make WFH and WFO equally effective.​",
      contentImg: <icons.homeOffice />,
      color: "green",
      tab: "WFH",
      rightCut: true,
    },
    {
      title: "​What’s your ROI",
      desc: "What's the value of productivity at personal, team and company levels? Your SHAPE scores integrate Return on Investment measures to guide where to place priority effort.​",
      contentImg: <icons.roi />,
      color: "dark-blue",
      tab: "ROI",
      rightCut: true,
    },
    {
      title: "​Get all the detail​",
      desc: "Dive deeper to analyse every question you answered. Scores help guide what really needs your attention.​",
      contentImg: <icons.detail />,
      color: "purple",
      tab: "Data",
      rightCut: true,
    },
    {
      title: "​The real comparison​",
      desc: "Get benchmark data that leads to real change. Learn how you compare with your own team and company average scores.​",
      contentImg: <icons.comparison />,
      color: "dark-blue",
      tab: "Benchmark",
      rightCut: true,
    },
    {
      title: "​Pulse performance​",
      desc: "Select your audience and re-survey specific topics you’ve acted upon to keep improving throughout the year​.",
      contentImg: <icons.pulse />,
      color: "teal",
      tab: "Pulse",
    },
  ]

  useEffect(() => {
    let snapContainerService = document.getElementById("snapContainerService")
    if (snapContainerService) snapContainerService.focus()
  })

  // useEffect(() => {
  //   document.getElementById("service-1").addEventListener("wheel", function(e) {
  //     snapScrollCustom(e, 1)
  //   })
  //   document.getElementById("service-2").addEventListener("wheel", function(e) {
  //     snapScrollCustom(e, 2)
  //   })
  // }, [])

  // const snapScrollCustom = (e, currentIndex) => {
  //   e.preventDefault()

  //   let nextSection = null
  //   if (e.deltaY >= 0) {
  //     //next section
  //     nextSection = currentIndex < 3 ? currentIndex + 1 : null
  //   } else {
  //     //previous section
  //     nextSection = currentIndex > 1 ? currentIndex - 1 : null
  //   }
  //   if (nextSection === null) {
  //     return
  //   }
  //   let nextSectionObj = document.getElementById(`service-${nextSection}`)
  //   if (nextSectionObj) {
  //     nextSectionObj.scrollIntoView({
  //       behavior: "smooth",
  //       block: nextSection === 3 ? "start" : "center",
  //     })
  //   }
  // }
  return (
    <>
      <Header />
      <SEO title="Service" />
      <div
        tabIndex="0"
        className="snapContainerService"
        id="snapContainerService"
      >
        <div className="hero section" id="service-1">
          <Container className="measure-custom-container">
            <Row>
              <Col
                lg={{ order: 1, span: 12 }}
                xl={{ order: 1, span: 6 }}
                className="custom-padding"
              >
                <div className="flexCenter ">
                  <h1>Measure what matters​</h1>
                  <p>
                    Get intelligence that counts. Measure everything about
                    people experience in one place. Make better decisions. ​
                  </p>
                  <a href="/launch">Launch SHAPE</a>
                </div>
              </Col>
              <Col
                lg={{ order: 12, span: 12 }}
                xl={{ order: 12, span: 6 }}
                className="custom-padding"
              >
                <div>
                  <img
                    src={icons.measure}
                    alt="SHAPE-service"
                    className="measure-hero-image"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section content" id="service-2">
          <ExplorerView />
        </div>
        <div className="section">
          <div id="service-3" className="service3">
            <div className="power-people">
              <div className="powerPeopleHeading">
                Power your people to go from <span>good to great</span>
              </div>
              <div className="powerPeopleDesc">
                SHAPE is the System for Health, Attendance, Productivity and
                Engagement. Measure over 150 factors that drive workplace
                performance. High performance means all these moving parts work
                together in perfect harmony. SHAPE scores these factors and
                guides everyone towards effective action. ​
              </div>
            </div>
            <h1>Action through insights</h1>

            <VerticalCarousel data={carouselDataMeasure} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Service
